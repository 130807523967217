<script>
import Vue from "vue";
import store from "@/state/store";

import { required, minLength, maxLength, sameAs } from "vuelidate/lib/validators";

import { mapState } from "vuex";
import appConfig from "@/app.config";
import Layout from "@/router/layouts/auth";
import {
  AccountStatus,
  notificationMethods, SessionLoginState
} from "@/state/helpers";

import VueCryptojs from 'vue-cryptojs';
Vue.use(VueCryptojs);

/**
 * Forgot Password component
 */
export default {
  page: {
    title: "Account setup",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout },
  data() {
    return {
      password: "",
      repeat_password: "",
      submitted: false
    };
  },
  computed: {
    notification() {
      return this.$store ? this.$store.state.notification : null;
    }
  },
  validations: {
    password: { required, minLength: minLength(8), maxLength: maxLength(64) },
    repeat_password: {
      sameAsPassword: sameAs('password')
    }
  },
  methods: {
    ...notificationMethods,
    continue_without_pw() {
      const router = this.$router; // Promise hell...
      this.$swal({
        title: this.$t('setup.password.continue_title'),
        text: '',
        confirmButtonText: this.$t('generic.yes'),
        cancelButtonText: this.$t('generic.no'),
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: true,
      }).then((result) => {
        if(result.value) {
          fetch(process.env.VUE_APP_ROOT_API + 'v1/@me/acsrf-token', {credentials: 'include'})
              .then(response => {
                if(response.ok){
                  return response.json();
                }
              })
              .then(data => {
                /* Actual request */
                let payload = {
                  acsrf_token: data.acsrf_token
                };
                fetch(process.env.VUE_APP_ROOT_API + 'v1/@me/disable-password', {
                  method: 'POST',
                  body: JSON.stringify(payload),
                  credentials: 'include'
                })
                .then(response => {
                  return response.json();
                })
                .then(data => {
                  if(!data.status) {
                    if(data.error == 'invalid-state') {
                      /* Local state got desynchronized, reevaluating */
                      console.log('Resolving state desynchronization');
                      this.$router.push({name: "login"}).catch((error) => {});
                    } else if(data.error == 'rate-limited') this.handle_rate_limit_error();
                    else this.handle_fail_and_reset();
                  }
                  else this.$router.push({name: "setup_account"}).catch((error) => {});
                })
                .catch(error => {
                  console.log(`[ERROR] ${error}`);
                  this.handle_fail_and_reset();
                });
                /* --- */
              })
              .catch(error => {
                console.log(error);
                this.handle_fail_and_reset();
              });
        }
      });
    },
    handle_fail_and_reset() {
      this.submitted = false;
      this.$swal( {
        icon: 'error',
        title: this.$t('error.server.generic.title'),
        text: this.$t('error.server.generic.message')
      });
    },
    handle_rate_limit_error() {
      this.submitted = false;
      this.$swal( {
        icon: 'warning',
        title: this.$t('error.server.ratelimit.title'),
        text: this.$t('error.server.ratelimit.message')
      });
    },
    handle_success(username) {
      const router = this.$router; // Promise hell...
      this.$swal({
        icon: 'success',
        title: this.$t('setup.password.success.title'),
        text: this.$t('setup.completed'),
        confirmButtonText: this.$t('generic.ok')
      }).then(function() {
        router.push({name: "index"}).catch((error) => {});
      });
    },
    try_submit() {
      if(this.submitted) return;
      this.submitted = true;

      this.$v.$touch();
      if(this.$v.$invalid) {
        this.submitted = false;
      } else {
        // Plain text password never leaves the client
        const hashed_password = this.CryptoJS.SHA256(this.password).toString(this.CryptoJS.enc.Hex);
        fetch(process.env.VUE_APP_ROOT_API + 'v1/@me/acsrf-token', {credentials: 'include'})
        .then(response => {
          if(response.ok){
            return response.json();
          }
        })
        .then(data => {
          /* Actual request */
          let payload = {
            acsrf_token: data.acsrf_token,
            password: hashed_password
          };
          fetch(process.env.VUE_APP_ROOT_API + 'v1/@me/update-password', {
            method: 'POST',
            body: JSON.stringify(payload),
            credentials: 'include'
          })
              .then(response => {
                return response.json();
              })
              .then(data => {
                if(!data.status) {
                  if(data.error == 'invalid-state') {
                    /* Local state got desynchronized, reevaluating */
                    console.log('Resolving state desynchronization');
                    this.$router.push({name: "login"}).catch((error) => {});
                  } else if(data.error == 'rate-limited') this.handle_rate_limit_error();
                    else this.handle_fail_and_reset();
                }
                else this.handle_success();
              })
              .catch(error => {
                console.log(`[ERROR] ${error}`);
                this.handle_fail_and_reset();
              });
          /* --- */
        })
        .catch(error => {
          console.log(error);
          this.handle_fail_and_reset();
        });
      }
    }
  }
};
</script>

<template>
  <Layout>
    <div class="row justify-content-center">
      <div class="col-md-8 col-lg-6 col-xl-5">
        <div class="card overflow-hidden">
          <div class="bg-soft-primary">
            <div class="row">
              <div class="col-12">
                <div class="text-primary p-3">
                  <h4 class="text-white text-right p-0">{{$t('setup.password.title')}}</h4>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body pt-0">
            <div>
              <router-link tag="a" to="/">
                <div class="avatar-md profile-user-wid mb-4">
                  <span class="avatar-title rounded-circle bg-light">
                    <img src="@/assets/images/logo.svg" alt height="34" />
                  </span>
                </div>
              </router-link>
            </div>
            <div
                v-if="notification.message"
                :class="'alert ' + notification.type"
            >{{notification.message}}</div>
            <h5 class="text-center">{{$t('setup.password.info')}}</h5>
            <h6 class="text-center text-muted">{{$t('setup.password.info_2')}}</h6>
            <div class="pt-2 pb-2 text-center">
              <b>
                <a href="#" v-bind:class="continue_without_pw" @click="continue_without_pw">{{$t('setup.password.continue')}}</a>
              </b>
            </div>
            <div class="p-2">
              <form @submit.prevent="try_submit">
                <div class="form-group">
                  <label for="password">{{$t('generic.password')}}</label>
                  <input
                    type="password"
                    v-model.trim="password"
                    class="form-control"
                    id="password"
                    :class="{ 'is-invalid': $v.password.$error }"
                    @change="$v.password.$touch()"
                  />
                  <div v-if="$v.password.$error" class="invalid-feedback">
                    <span v-if="!$v.password.required">{{$t('setup.password.errors.required')}}</span>
                    <span v-if="!$v.password.minLength">{{$t('setup.password.errors.length')}}</span>
                    <span v-if="!$v.password.maxLength">{{$t('setup.password.errors.length')}}</span>
                  </div>
                </div>
                <div class="form-group">
                  <label for="repeat_password">{{$t('setup.password.prompt_repeat')}}</label>
                  <input
                      type="password"
                      v-model.trim="repeat_password"
                      class="form-control"
                      id="repeat_password"
                      :class="{ 'is-invalid': $v.repeat_password.$error }"
                      @change="$v.repeat_password.$touch()"
                  />
                  <div v-if="$v.repeat_password.$error" class="invalid-feedback">
                    <span v-if="!$v.repeat_password.sameAsPassword">{{$t('setup.password.errors.match')}}</span>
                  </div>
                </div>
                <div class="mt-3">
                  <b-button type="submit" variant="primary" class="btn-block">{{$t('setup.password.confirm')}}</b-button>
                </div>
              </form>
            </div>
          </div>
          <!-- end card-body -->
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
    <div class="mt-3 text-center">
      <small>
        © 2017 - {{new Date().getFullYear()}} {{$t('generic.legal_disclaimer')}} {{$t('generic.provider')}}<br>
        <small style="font-size: 10px;">
          <a href="https://cftools.cloud/legal/imprint" style="color: unset !important;">Imprint</a>
          /
          <a href="https://cftools.cloud/legal/privacy" style="color: unset !important;">Privacy policy</a>
        </small>
      </small>
    </div>
  </Layout>
</template>

<style lang="scss" module></style>
